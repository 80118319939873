import './index.css';
import React, { useCallback, useEffect, useState } from 'react';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import LogoutIcon from '@mui/icons-material/Logout';
// import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import MaterialTooltipIconButton from '../../shared/UI/components/materialTooltipIconButton/MaterialTooltipIconButton';
import dayjs from 'dayjs';
import { format, parseISO, addDays, subDays } from 'date-fns';
import { Constants, EXPORT_MODAL, SATELLITES, SENTINEL3 } from '../Constants';
import CustomizedDate from './components/customizedDate/CustomizedDate';
import SatteliteSelect from './components/satteliteSelect/SatteliteSelect';
import MaterialAutoComplete from '../../shared/UI/components/materialAutoComplete/MaterialAutoComplete';
import { InputAdornment } from '@mui/material';
import EventIcon from '@mui/icons-material/Event';
import { MobileDatePicker } from '@mui/x-date-pickers';
// import FeedbackOutlinedIcon from '@mui/icons-material/FeedbackOutlined';
import DownloadMenu from './components/downloadMenu/DownloadMenu';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getSelectedDateValueService, handleLocationChangeService, onDayChangeService, shouldDisableDateService } from './services/topbarServices';

function TopBar(props) {
	const {
		options,
		setLocation,
		logoutAction,
		selectedLocation,
		editLocation,
		setModalOpened,
		allowExport,
		images,
		setStartMonthFilter,
		selectedLayers = [],
		showLayersAfterDateChange,
		satelliteView,
		setSatelliteView,
		trendData,
		defaultDate,
		showDefaultLayers,
		setShowDefaultLayers,
		lastDateDisplayed,
		isProUser,
		setSelectedLayers,
		removeAllLayers,
		selectedDate,
		setSelectedDate,
		userCanEdit,
		user,
		loading,
		setLoading,
		setConfirmModalState,
		isTablet,
		imagery,
		// is3D,
		// togglePitch,
		// rotate,
		userMode,
		setModalProps,
		exportDataRanges,
		planetImagery,
		openAoiList,
		setOpenAoiList,
	} = props;

	const iconSizeStyle = {
		width: '16px',
		height: '16px',
	};

	const isDisabled = selectedLocation?.name ? false : true;

	const [nextDateTooltip, setNextDateTooltip] = useState('');
	const [prevDateTooltip, setPrevDateTooltip] = useState('');
	const [selectedMonth, setSelectedMonth] = useState('');

	const settingsTooltip = 'Edit location';
	const exportTooltip = allowExport ? 'export' : 'export not allowed';
	const logoutTooltip = 'Logout';
	// const feedbackTooltip = 'feedback';

	let minDate = dayjs(format(selectedLocation.dateRange[0].start_date, Constants.DATE_FORMATS.DATE_FORMAT));
	// let maxDate = dayjs(format(selectedLocation.dateRange[0].end_date, Constants.DATE_FORMATS.DATE_FORMAT));
	let maxDate = dayjs(format(new Date(), Constants.DATE_FORMATS.DATE_FORMAT));
	// let defaultDate = format(subDays(new Date(), { days: 1 }), Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

	const shouldDisableDate = useCallback(
		(date) => {
			return shouldDisableDateService(date, satelliteView, selectedLocation);
		},
		[satelliteView, selectedLocation]
	);

	const onMonthChange = async (data) => {
		if (!data) return;
		setSelectedMonth(data);
	};

	const getSelectedDateValue = useCallback(
		(flag, date = undefined, satellite = satelliteView) => {
			let satelliteParam = satellite ? satellite : satelliteView;
			const result = getSelectedDateValueService(flag, date, satelliteParam, selectedDate, defaultDate, selectedLocation, planetImagery);
			return result;
		},
		[satelliteView, defaultDate, planetImagery, selectedDate, selectedLocation]
	);

	useEffect(() => {
		const reset = async () => {
			if (showDefaultLayers && lastDateDisplayed) {
				if (!shouldDisableDate(defaultDate) && !(satelliteView === SATELLITES.planet)) {
					if (!loading) {
						setLoading(true);
					}
					await showLayersAfterDateChange(selectedDate ? selectedDate['$d'] : defaultDate['$d']);
					setShowDefaultLayers(false);
					setLoading(false);
					setNextDateTooltip(getSelectedDateValue(1));
					setPrevDateTooltip(getSelectedDateValue(-1));
				}
			}
		};
		reset();
	}, [lastDateDisplayed, defaultDate, satelliteView, selectedDate, showDefaultLayers, getSelectedDateValue, loading, setLoading, setShowDefaultLayers, shouldDisableDate, showLayersAfterDateChange]);

	const onDayChange = async (data, view = '') => {
		await onDayChangeService(data, view, setSelectedMonth, setSelectedDate, setStartMonthFilter, selectedLayers, showLayersAfterDateChange, setNextDateTooltip, setPrevDateTooltip, getSelectedDateValue);
	};

	const handleChange = async (data) => {
		await handleLocationChangeService(data, selectedLocation, options, setSelectedLayers, setLocation, setShowDefaultLayers, setStartMonthFilter, selectedDate, defaultDate, isProUser);
	};

	const locationsOptions = options.map((el) => {
		return {
			...el,
			value: el.locationsId,
			label: el.country + ' ' + el.name,
		};
	});

	// let sattelitePlanetDisabled = true;
	if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD']) {
		// sattelitePlanetDisabled = false;
	}

	const satteliteOptions = [
		{
			title: 'Sentinel 3 (300m)',
			value: SATELLITES.copernicus,
			disabled: false,
		},
		{
			title: 'Planet (3m)',
			value: SATELLITES.planet,
			// disabled: sattelitePlanetDisabled,
			disabled: true,
		},
	];
	const RenderDate = (props) => {
		const { disabled, day } = props;
		let newProps = { ...props, selectedLocation: selectedLocation };
		let clouds = 0;
		let bloomspread = 0;
		let bloomlevel = 0;
		let formattedDate = format(day['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

		if (!disabled && trendData && trendData.length > 0) {
			let index = trendData.findIndex((data) => {
				let formatGivenDate = format(data.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
				return formatGivenDate === formattedDate;
			});

			if (trendData[index] && trendData[index].Clouds) {
				clouds = (Math.round(trendData[index].Clouds * 100) / 100).toFixed(2);
			}
			if (trendData[index] && trendData[index].BloomSpread) {
				bloomspread = (Math.round(trendData[index].BloomSpread * 100) / 100).toFixed(2);
			}

			if (trendData[index] && trendData[index].BloomLevel) {
				bloomlevel = (Math.round(trendData[index].BloomLevel * 100) / 100).toFixed(2);
			}

			if (index >= 0 && trendData[index]) {
				newProps = {
					...newProps,
					clouds: clouds,
					bloomspread: bloomspread,
					bloomlevel: bloomlevel,
				};
			}
		}

		let planetindex;
		let hasplanet = false;

		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD'] && selectedLocation.satellites['PSD'].dates) {
			planetindex = selectedLocation.satellites['PSD'].dates.indexOf(formattedDate);
			newProps = { ...newProps, planetindex: planetindex };
		}
		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites['PSD'] && selectedLocation.satellites['PSD'].dates && selectedLocation.satellites['PSD'].dates.length > 0) {
			hasplanet = 1;
			newProps = { ...newProps, planetindex: planetindex, hasplanet };
		}

		return <CustomizedDate {...newProps} />;
	};

	const continueToSuitableDate = async (data, newValue) => {
		if (data !== satelliteView) {
			await removeAllLayers();
			// setShowDefaultLayers(true);
		}
		onDayChange(newValue, data);
	};

	const satelliteViewChanged = async (data) => {
		// let formattedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		let date = selectedDate ? selectedDate['$d'] : defaultDate['$d'];
		let index, formerIndex;

		let modifiedDate = format(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		modifiedDate = dayjs(modifiedDate);

		const satelliteKey = data;
		const formerSatelliteKey = satelliteView;
		let formattedDate = format(modifiedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites[satelliteKey] && selectedLocation.satellites[satelliteKey].dates) {
			index = selectedLocation.satellites[satelliteKey].dates.indexOf(formattedDate);
			formerIndex = selectedLocation.satellites[formerSatelliteKey]?.dates.indexOf(formattedDate);
		}
		if (index >= 0) {
			if (data !== satelliteView) {
				await setSatelliteView(data);

				await removeAllLayers();
				setShowDefaultLayers(true);
				setNextDateTooltip(getSelectedDateValue(1, null, data));
				setPrevDateTooltip(getSelectedDateValue(-1, null, data));
			}
			showLayersAfterDateChange(modifiedDate ? modifiedDate['$d'] : date, data);
		} else {
			let formerLength = selectedLocation.satellites[formerSatelliteKey]?.dates.length;
			let exitSearch = false;
			let newValue;
			let text = 'Planet';
			if (data === SENTINEL3) {
				text = 'Sentinel 3';
			}

			for (let i = 0; i < formerLength - 1 && !exitSearch; i++) {
				let nextValue = selectedLocation.satellites[formerSatelliteKey].dates[formerIndex];
				nextValue = new Date(parseISO(nextValue));
				nextValue = addDays(nextValue, i);
				nextValue = format(nextValue, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

				let prevValue = selectedLocation.satellites[formerSatelliteKey].dates[formerIndex];
				prevValue = new Date(parseISO(prevValue));
				prevValue = subDays(prevValue, i);
				prevValue = format(prevValue, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

				if (prevValue) {
					let newIndex = selectedLocation.satellites[satelliteKey].dates.indexOf(prevValue);
					if (newIndex >= 0) {
						exitSearch = true;
						newValue = dayjs(format(new Date(parseISO(prevValue)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
					}
				}
				if (nextValue) {
					let newIndex = selectedLocation.satellites[satelliteKey].dates.indexOf(nextValue);
					if (newIndex >= 0) {
						exitSearch = true;
						newValue = dayjs(format(new Date(parseISO(nextValue)), Constants.DATE_FORMATS.SHORT_DATE_FORMAT));
					}
				}
			}

			const continueCallBack = async () => {
				await setSatelliteView(data);

				continueToSuitableDate(data, newValue);

				setConfirmModalState(null);

				setNextDateTooltip(getSelectedDateValue(1, null, data));
				setPrevDateTooltip(getSelectedDateValue(-1, null, data));
			};
			let originDate = format(modifiedDate['$d'], Constants.DATE_FORMATS.MONTH_NAME_LONG_YEAR_FORMAT);
			let destinationDate = format(newValue ? newValue['$d'] : selectedDate['$d'], Constants.DATE_FORMATS.MONTH_NAME_LONG_YEAR_FORMAT);
			setConfirmModalState({
				open: true,
				title: 'Missing data',
				message: `We do not have data for ${text} on ${originDate}.`,
				message2: 'What would wou like to do?',
				confirmButtonText: `Move to next available date -${destinationDate}`,
				confirmButtonCb: continueCallBack,
				cancelButtonCb: () => {
					setConfirmModalState(null);
				},
			});
		}
	};

	let dateFormat = user && user.units === 'metric' ? 'DD/MM/YYYY' : 'MM/DD/YYYY';

	const changeDateFromArrowClick = (flag) => {
		if (!selectedLocation || !selectedLocation.name) {
			return;
		}
		// flag > 0 -> move to next, flag < 0 -> move to prev
		let currentDate = selectedDate ? selectedDate : defaultDate;
		currentDate = currentDate['$d'];
		currentDate = format(currentDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		let key = satelliteView;
		let datesArray = selectedLocation && selectedLocation.satellites && selectedLocation.satellites[key] && selectedLocation.satellites[key].dates && selectedLocation.satellites[key].dates.length ? selectedLocation.satellites[key].dates : undefined;

		if (datesArray) {
			let resultDate = undefined;
			let index = datesArray.findIndex((el) => el === currentDate);
			if (index >= 0 && index < datesArray.length) {
				if (flag === 1 && index > 0) {
					// flag=1 -> move to next date
					resultDate = datesArray[index - 1];
				} else if (flag === -1 && index + 1 <= datesArray.length) {
					// flag = -1 move to prev date
					resultDate = datesArray[index + 1];
				}
				if (resultDate) {
					onDayChange(dayjs(resultDate));
				}
			}
		}
	};

	const ActionsComponent = (
		<div className="actions-container">
			<MaterialAutoComplete
				// value={selectedLocation.name? `${selectedLocation.country} + ' ' + ${selectedLocation.name}` : null}
				value={selectedLocation.name ? selectedLocation.locationsId : null}
				blurOnClose={true}
				options={locationsOptions}
				className={'select-location-container'}
				isTablet={isTablet}
				handleChange={handleChange}
				openAoiList={openAoiList}
				setOpenAoiList={setOpenAoiList}
			/>

			<MaterialTooltipIconButton title={prevDateTooltip} onClick={() => changeDateFromArrowClick(-1)} Icon={NavigateNextIcon} iconSizeStyle={{ transform: 'rotate(180deg)' }} fontSize="small" spanStyle={{ height: '33px', width: '33px', margin: '0 10px' }} />

			<LocalizationProvider dateAdapter={AdapterDayjs}>
				{!isTablet ? (
					<DatePicker
						minDate={minDate}
						maxDate={maxDate}
						disabled={isDisabled || (!images && !images?.length > 0)}
						shouldDisableDate={shouldDisableDate}
						slots={{
							day: RenderDate,
						}}
						value={selectedDate ? selectedDate : defaultDate}
						onMonthChange={onMonthChange}
						onChange={onDayChange}
						defaultCalendarMonth={selectedMonth}
						className="topbar-date-picker"
						format={dateFormat}
					/>
				) : (
					<MobileDatePicker
						minDate={minDate}
						maxDate={maxDate}
						disabled={isDisabled || (!images && !images?.length > 0)}
						shouldDisableDate={shouldDisableDate}
						disableCloseOnSelect={false}
						slots={{
							day: RenderDate,
						}}
						slotProps={{
							textField: {
								InputProps: {
									endAdornment: (
										<InputAdornment position="end">
											<EventIcon color={isDisabled || (!images && !images?.length > 0) ? 'disabled' : 'inherit'} />
										</InputAdornment>
									),
								},
							},
						}}
						onAccept={(data) => onDayChange(data)}
						value={selectedDate ? selectedDate : defaultDate}
						onMonthChange={onMonthChange}
						defaultCalendarMonth={selectedMonth}
						className="topbar-date-picker"
						format={dateFormat}
					/>
				)}
			</LocalizationProvider>

			<MaterialTooltipIconButton title={nextDateTooltip} onClick={() => changeDateFromArrowClick(1)} Icon={NavigateNextIcon} fontSize="small" spanStyle={{ height: '33px', width: '33px', margin: '0 10px' }} />

			<SatteliteSelect options={satteliteOptions} menuItemClassName={'sattelite-menu-item'} fontStyleClassName="satellite-text-style" satelliteView={satelliteView} setSatelliteView={satelliteViewChanged} isMenuDisabled={!selectedLocation.name} />
		</div>
	);

	return (
		<div className="main-top-bar">
			<div className="logo-container">
				<img src="/logo2.png" className="logo" alt="BlueGreen Water Technologies" />
			</div>
			<div className="border-new-ui"></div>

			{ActionsComponent}

			<div className="icons-container" style={{ width: userMode === 'expert' ? '179px' : '108px' }}>
				{/* <Button className='popupButton buttonPitch' variant="text" onClick={(event) => togglePitch(event, imagery.imageryId) } id={`buttonImagery_bloom_pitch`} pitch={is3D ? '3D' : '2D'} layer-mode="bloom" imagery-id={imagery ?imagery.imageryId : ''}  >{is3D ? '2D' : '3D'}</Button>
 
            {is3D  ?<Button variant="text" onClick={rotate} >360</Button> : ''} */}

				{/* {!isDisabled && userCanEdit && <MaterialTooltipIconButton iconButtonStyle={{ padding: '0' }} className="icons-sizes" title={settingsTooltip} isDisabled={isDisabled && !userCanEdit} edge={'end'} Icon={SettingsTwoToneIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={editLocation} />} */}
				{isTablet ? (
					''
				) : (
					<MaterialTooltipIconButton
						iconButtonStyle={{ display: 'flex', padding: 0, color: 'rgba(0, 0, 0, 0.54)' }}
						className="icons-sizes"
						title={settingsTooltip}
						isDisabled={isDisabled || !userCanEdit}
						edge={'end'}
						Icon={SettingsTwoToneIcon}
						iconSizeStyle={iconSizeStyle}
						fontSize={'small'}
						onClick={editLocation}
					/>
				)}

				{/* {allowExport && !isDisabled && (
                    <MaterialTooltipIconButton iconButtonStyle={{ padding: '0' }} className="icons-sizes" title={exportTooltip} isDisabled={!allowExport || isDisabled} edge={'end'} Icon={GetAppOutlinedIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={() => setModalOpened(true)} />
                )} */}

				{/* {<MaterialTooltipIconButton iconButtonStyle={{ padding: '0' }} className="icons-sizes" title={exportTooltip} isDisabled={!allowExport || isDisabled} edge={'end'} Icon={GetAppOutlinedIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={() => setModalOpened(true)} />} */}

				{isTablet ? '' : userMode === 'expert' ? <DownloadMenu imagery={imagery} satelliteView={satelliteView} selectedLocation={selectedLocation} /> : ''}

				{
					<MaterialTooltipIconButton
						iconButtonStyle={{ display: 'flex', padding: 0, color: 'rgba(0, 0, 0, 0.54)' }}
						className="icons-sizes"
						title={exportTooltip}
						isDisabled={!allowExport || isDisabled}
						edge={'end'}
						Icon={OpenInNewIcon}
						iconSizeStyle={iconSizeStyle}
						fontSize={'small'}
						onClick={() => {
							setModalOpened(true);
							setModalProps({
								open: true,
								closeModal: () => setModalOpened(false),
								modalTitle: 'Export Data',
								type: EXPORT_MODAL,
								actionButtonClick: exportDataRanges,
								startDateTitle: 'From:',
								endDateTitle: 'To:',
								actionButtonTitle: 'Export',
								closeActionButtonTitle: 'Close',
								setModalOpened: setModalOpened,
								location: selectedLocation,
							});
						}}
					/>
				}

				{/* <MaterialTooltipIconButton title={feedbackTooltip} iconButtonStyle={{  display: 'flex' ,padding: 0 }} edge={'end'} Icon={FeedbackOutlinedIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} spanStyle={{ height: '16px', width: '16px' }} /> */}

				<span className="icons-divider"></span>

				<MaterialTooltipIconButton style iconButtonStyle={{ display: 'flex', padding: 0, color: 'rgba(0, 0, 0, 0.54)' }} className="icons-sizes" title={logoutTooltip} edge={'end'} Icon={LogoutIcon} iconSizeStyle={iconSizeStyle} fontSize={'small'} onClick={logoutAction} />
			</div>
		</div>
	);
}

export default TopBar;
