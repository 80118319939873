import { sub as subDays, add as addDays, differenceInMonths, differenceInQuarters, differenceInWeeks, subHours, addHours } from 'date-fns';
import { Constants, SATELLITES } from '../components/Constants';
import _ from 'lodash';
import { isBlank } from '../components/Services';
import dayjs from 'dayjs';

export const getWeatherArrayType = (location) => {
	let weatherTypes = [
		{
			value: 'Temp',
			title: 'Temperature',
			ariaLabel: 'Temperature weather type',
		},
		{
			value: 'Wind',
			title: 'Wind',
			ariaLabel: 'Wind weather type',
		},
		{
			value: 'Precipitation',
			title: 'Precipitation',
			ariaLabel: 'Precipitation weather type',
		},
	];
	if (location && location.saltWater) {
		return weatherTypes;
	}
	return [
		...weatherTypes,
		{
			value: 'UV',
			title: 'UV',
			ariaLabel: 'uv weather type',
		},
		{
			value: 'Visibility',
			title: 'Visibility',
			ariaLabel: 'Visibility weather type',
		},
	];
};

// export const getTodayProperty = (key, period) => {
// 	if (period && period.current && period.current[period.current.length - 1] && period.current[period.current.length - 1][key]) {
// 		let value = period.current[period.current.length - 1][key];
// 		value = (Math.round(value * 100) / 100).toFixed(2);
// 		return value;
// 	}
// 	return 0;
// };

const calculateViaPropertyName = (data, key) => {
	return data.filter((d) => !isNaN(d[key])).reduce((a, b) => a + b[key], 0) / data.filter((d) => !isNaN(d[key])).length;
};

const calculateClouds = (data) => {
	// return  data.filter((d) => !isNaN(d.Clouds)).reduce((a, b) => a + b.Clouds, 0) / data.filter((d) => !isNaN(d.Clouds)).length
	return calculateViaPropertyName(data, 'Clouds');
};

const calculateSS = (data) => {
	// return  data.filter((d) => !isNaN(d.SS)).reduce((a, b) => a + b.SS, 0) / data.filter((d) => !isNaN(d.SS)).length
	return calculateViaPropertyName(data, 'SS');
};

const calculateSML = (data) => {
	// data.filter((d) => !isNaN(d.SML)).reduce((a, b) => a + b.SML, 0) / data.filter((d) => !isNaN(d.SML)).length
	return calculateViaPropertyName(data, 'SML');
};

const calculateCDOM = (data) => {
	// data.filter((d) => !isNaN(d.CDOM)).reduce((a, b) => a + b.CDOM, 0) / data.filter((d) => !isNaN(d.CDOM)).length
	return calculateViaPropertyName(data, 'CDOM');
};

const calculateBloomLevel = (data) => {
	// data.filter((d) => !isNaN(d.BloomLevel)).reduce((a, b) => a + b.BloomLevel, 0) / data.filter((d) => !isNaN(d.BloomLevel)).length
	return calculateViaPropertyName(data, 'BloomLevel');
};

const calculateBloomSpread = (data) => {
	// data.filter((d) => !isNaN(d.BloomSpread)).reduce((a, b) => a + b.BloomSpread, 0) / data.filter((d) => !isNaN(d.BloomSpread)).length
	return calculateViaPropertyName(data, 'BloomSpread');
};

const calculateVisibility = (data) => {
	return data.filter((d) => !isNaN(d.Weather?.cloudcover || 0)).reduce((a, b) => a + (100 - (b.Weather?.cloudcover || 0)), 0) / data.filter((d) => !isNaN(d.Weather?.cloudcover || 1)).length;
};

///start methods calculating weather params

const calculateWeatherParamsViaKey = (data, key) => {
	return data.filter((d) => !isNaN(d.weather[d.weatherKeys[0]][key])).reduce((a, b) => a + b.weather[b.weatherKeys[0]][key], 0) / data.filter((d) => !isNaN(d.weather[d.weatherKeys[0]][key])).length;
};

export const rollupData = (data) => {
	let key = data[0].weatherKeys;
	const value = {
		date: data[0].date,
		Clouds: calculateClouds(data),
		SS: calculateSS(data),
		SML: calculateSML(data),
		CDOM: calculateCDOM(data),
		BloomLevel: calculateBloomLevel(data),
		Visibility: calculateVisibility(data),
		BloomSpread: calculateBloomSpread(data),
		weather: {},
		weatherKeys: data[0].weatherKeys,
		BGI: data[0].BGI,
	};
	value.weather[key] = {
		temperature_2m: calculateWeatherParamsViaKey(data, 'temperature_2m'),
		temperature_2m_mean: calculateWeatherParamsViaKey(data, 'temperature_2m_mean'),
		temperature_2m_max: calculateWeatherParamsViaKey(data, 'temperature_2m_max'),
		temperature_2m_min: calculateWeatherParamsViaKey(data, 'temperature_2m_min'),

		cloudcover: calculateWeatherParamsViaKey(data, 'cloudcover'),
		cloudcover_mean: calculateWeatherParamsViaKey(data, 'cloudcover_mean'),
		cloudcover_max: calculateWeatherParamsViaKey(data, 'cloudcover_max'),
		cloudcover_min: calculateWeatherParamsViaKey(data, 'cloudcover_min'),
		cloudcover_high: calculateWeatherParamsViaKey(data, 'cloudcover_high'),
		cloudcover_mid: calculateWeatherParamsViaKey(data, 'cloudcover_mid'),
		cloudcover_low: calculateWeatherParamsViaKey(data, 'cloudcover_low'),

		windspeed_10m: calculateWeatherParamsViaKey(data, 'windspeed_10m'),
		windspeed_10m_mean: calculateWeatherParamsViaKey(data, 'windspeed_10m_mean'),
		windspeed_10m_max: calculateWeatherParamsViaKey(data, 'windspeed_10m_max'),
		windspeed_10m_min: calculateWeatherParamsViaKey(data, 'windspeed_10m_min'),

		windgusts_10m: calculateWeatherParamsViaKey(data, 'windgusts_10m'),
		windgusts_10m_mean: calculateWeatherParamsViaKey(data, 'windgusts_10m_mean'),
		windgusts_10m_max: calculateWeatherParamsViaKey(data, 'windgusts_10m_max'),
		windgusts_10m_min: calculateWeatherParamsViaKey(data, 'windgusts_10m_min'),

		precipitation: calculateWeatherParamsViaKey(data, 'precipitation'),

		rain: calculateWeatherParamsViaKey(data, 'rain'),

		showers: calculateWeatherParamsViaKey(data, 'showers'),

		snowfall: calculateWeatherParamsViaKey(data, 'snowfall'),

		uv_index: calculateWeatherParamsViaKey(data, 'uv_index'),
	};
	return value;
};

export const getValues = (trendData, dateRange, context) => {
	let period = {
			current: [],
			previous: [],
			averages: {},
			max: {},
			min: {},
			mean: {},
		},
		dateDelta,
		baseDate = trendData[trendData.length - 1].date,
		thisYear = isNaN(Number(dateRange.id)) ? new Date().getFullYear() : Number(dateRange.id),
		lastYear,
		previousYear;
	let lastJan1 = new Date(lastYear, 0, 1);

	// YearToDate and specific year are handled differently
	if (isNaN(dateRange.id) && dateRange.id !== 'YearToDate') {
		switch (dateRange.id) {
			case 'PastWeek':
				period.current = trendData.filter((_data) => _data.date > subDays(baseDate, { weeks: 1 }));
				period.previous = trendData.filter((_data) => _data.date > subDays(baseDate, { weeks: 2 }) && _data.date <= subDays(baseDate, { weeks: 1 }));
				break;
			case 'PastMonth':
				period.current = trendData.filter((_data) => _data.date > subDays(baseDate, { months: 1 }));
				period.previous = trendData.filter((_data) => _data.date > subDays(baseDate, { months: 2 }) && _data.date <= subDays(baseDate, { months: 1 }));
				break;
			case 'Past3Months':
				period.current = trendData.filter((_data) => _data.date > subDays(baseDate, { months: 3 }));
				period.previous = trendData.filter((_data) => _data.date > subDays(baseDate, { months: 6 }) && _data.date <= subDays(baseDate, { months: 3 }));
				break;

			case 'Past6Months':
				thisYear = Number(dateRange.id);
				lastYear = thisYear - 1;
				lastJan1 = new Date(lastYear, 0, 1);

				period.current = trendData.filter((_data) => _data.date >= subDays(baseDate, { months: 6 }) && _data.date <= baseDate);

				if (lastJan1 > trendData[0].date) {
					period.previous = trendData.filter((_data) => _data.date >= subDays(baseDate, { months: 18 }) && _data.date <= subDays(baseDate, { months: 6 }));
				}

				break;

			case 'PastYear':
				thisYear = Number(dateRange.id);
				lastYear = thisYear - 1;
				lastJan1 = new Date(lastYear, 0, 1);

				period.current = trendData.filter((_data) => _data.date >= subDays(baseDate, { years: 1 }) && _data.date <= baseDate);

				if (lastJan1 > trendData[0].date) {
					period.previous = trendData.filter((_data) => _data.date >= subDays(baseDate, { years: 2 }) && _data.date <= subDays(baseDate, { years: 1 }));
				}

				break;
			case 'Monthly':
				// rollup each month's data into a single average value by month
				dateDelta = differenceInMonths(trendData[trendData.length - 1].date, trendData[0].date);

				for (let month = 1; month <= dateDelta; month++) {
					let monthData = trendData.filter((_data) => _data.date >= subDays(baseDate, { months: month }) && _data.date <= subDays(baseDate, { months: month - 1 }));
					period.current.unshift(rollupData(monthData));

					if (month < dateDelta) {
						monthData = trendData.filter((_data) => _data.date >= subDays(baseDate, { months: month + 1 }) && _data.date <= subDays(baseDate, { months: month }));
						period.previous.unshift(rollupData(monthData));
					}
				}

				break;
			case 'Quarterly':
				// rollup each quarter's data into a single average value by quarter
				dateDelta = differenceInQuarters(trendData[trendData.length - 1].date, trendData[0].date);

				for (let quarter = 1; quarter <= dateDelta; quarter++) {
					let quarterData = trendData.filter((_data) => _data.date >= subDays(baseDate, { months: quarter * 3 }) && _data.date <= subDays(baseDate, { months: quarter * 3 - 3 }));
					period.current.unshift(rollupData(quarterData));

					if (quarter < dateDelta) {
						quarterData = trendData.filter((_data) => _data.date >= subDays(baseDate, { months: quarter * 3 + 3 }) && _data.date <= subDays(baseDate, { months: quarter * 3 }));
						period.previous.unshift(rollupData(quarterData));
					}
				}

				break;
			case 'Yearly':
				dateDelta = differenceInWeeks(trendData[trendData.length - 1].date, trendData[0].date);

				for (let week = 1; week <= dateDelta; week++) {
					let monthData = trendData.filter((_data) => _data.date >= subDays(baseDate, { weeks: week }) && _data.date <= subDays(baseDate, { weeks: week - 1 }));
					if (monthData && monthData[0]) {
						period.current.unshift(rollupData(monthData));
					}

					if (week < dateDelta) {
						monthData = trendData.filter((_data) => _data.date >= subDays(baseDate, { weeks: week + 1 }) && _data.date <= subDays(baseDate, { weeks: week }));
						if (monthData && monthData[0]) {
							period.previous.unshift(rollupData(monthData));
						}
					}
				}

				break;

			default:
				// rollup data by week into average values
				dateDelta = 52;
				lastYear = thisYear; // use lastYear as the setting for the selected year
				previousYear = lastYear - 1;

				let previousJan1 = new Date(previousYear, 0, 1);

				for (let week = 1; week <= dateDelta; week++) {
					// eslint-disable-next-line no-loop-func
					let weekData = trendData.filter((_data) => _data.date.getFullYear() >= lastYear && _data.date.getFullYear() <= thisYear && _data.date > addDays(lastJan1, { weeks: week - 1 }) && _data.date <= addDays(lastJan1, { weeks: week }));
					period.current.push(rollupData(weekData));

					if (week > 1) {
						// eslint-disable-next-line no-loop-func
						weekData = trendData.filter((_data) => _data.date.getFullYear() >= previousYear && _data.date.getFullYear() <= lastYear && _data.date > addDays(previousJan1, { weeks: week - 1 }) && _data.date <= addDays(previousJan1, { weeks: week }));

						period.previous.push(rollupData(weekData));
					}
				}

				break;
		}
	} else {
		// this will handle YearToDate and specific year
		thisYear = dateRange.id === 'YearToDate' ? thisYear : Number(dateRange.id);
		lastYear = thisYear - 1;
		lastJan1 = new Date(lastYear, 0, 1);

		period.current = trendData.filter((_data) => _data.date.getFullYear() === thisYear);

		if (lastJan1 >= trendData[0].date) {
			period.previous = trendData.filter((_data) => _data.date.getFullYear() === lastYear);
		}
	}

	for (let trendType of Constants.TREND_TYPES) {
		let currentValues, averageValue, previousAverage, delta;

		switch (trendType.key) {
			case 'UV':
				period.current = period.current.map((_data, index) => {
					let uv = 0;
					if (_data.weather && _data.weather[_data.weatherKeys[0]] && _data.weather[_data.weatherKeys[0]].uv_index) {
						uv = _data.weather[_data.weatherKeys[0]].uv_index;
					}
					return {
						..._data,
						UV: uv,
					};
				});
				currentValues = period.current.map((datum) => datum.UV);
				period.max[trendType.key] = Number(Math.max(...currentValues).toFixed(1));
				period.min[trendType.key] = Number(Math.min(...currentValues).toFixed(1));
				//period.mean[trendType.key] = Number(_.mean(...currentValues).toFixed(1));
				previousAverage = period.previous.map((datum, index) => datum.weather[datum.weatherKeys[0]]?.uv_index).reduce((a, b) => a + b, 0) / period.previous.length;
				break;

			case 'Temp':
				period.current = period.current.map((_data, index) => ({
					..._data,
					Temp: _data?.weather[_data.weatherKeys[0]]?.temperature_2m || 0,
				}));
				currentValues = period.current.map((datum) => datum.Temp);
				period.max[trendType.key] = Number(Math.max(...period.current.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.temperature_2m_max || 0)).toFixed(1));
				period.min[trendType.key] = Number(Math.min(...period.current.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.temperature_2m_min || 0)).toFixed(1));
				//period.mean[trendType.key] = Number(_.mean(...currentValues).toFixed(1));
				previousAverage = period.previous.map((datum) => datum?.Weather?.temperature_2m || 0).reduce((a, b) => a + b, 0) / period.previous.length;
				break;

			case 'Wind':
				period.current = period.current.map((_data, index) => ({
					..._data,
					Wind: _data?.weather[_data.weatherKeys[0]]?.windspeed_10m || 0,
				}));
				currentValues = period.current.map((datum) => datum.Wind);
				period.max[trendType.key] = Number(Math.max(...period.current.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.windspeed_10m_max || 0)).toFixed(1));
				period.min[trendType.key] = Number(Math.min(...period.current.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.windspeed_10m_min || 0)).toFixed(1));
				//period.mean[trendType.key] = Number(_.mean(...(period.current.map((datum) => datum.Weather.windspeed_10m_mean))).toFixed(1));
				previousAverage = period.previous.map((datum, index) => datum?.weather[datum.weatherKeys[index]]?.windspeed_10m || 0).reduce((a, b) => a + b, 0) / period.previous.length;
				break;

			case 'Visibility':
				period.current = period.current.map((_data, index) => {
					return {
						..._data,
						Visibility: 100 - _data?.Clouds || 0,
					};
				});
				currentValues = period.current.map((datum) => datum.Visibility);
				period.max[trendType.key] = Number(Math.max(...period.current.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.cloudcover_max || 0)).toFixed(1));
				period.min[trendType.key] = Number(Math.min(...period.current.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.cloudcover_min || 0)).toFixed(1));
				//period.mean[trendType.key] = Number(_.mean(...(period.current.map((datum) => datum.Weather.cloudcover_mean))).toFixed(1));
				previousAverage = period.previous.map((datum, index) => datum?.weather[datum.weatherKeys[0]]?.windspeed_10m || 0).reduce((a, b) => a + b, 0) / period.previous.length;
				break;

			case 'Precipitation':
				let precipitation = 0;

				period.current = period.current.map((_data, index) => {
					if (_data.weather && _data.weather[_data.weatherKeys[0]] && _data.weather[_data.weatherKeys[0]].precipitation) {
						precipitation = _data.weather[_data.weatherKeys[0]].precipitation;
					}
					return { ..._data, Precipitation: precipitation };
				});
				currentValues = period.current.map((datum) => datum.Precipitation);
				period.max[trendType.key] = Number(Math.max(...currentValues).toFixed(1));
				period.min[trendType.key] = Number(Math.min(...currentValues).toFixed(1));
				//period.mean[trendType.key] = Number(_.mean(...currentValues).toFixed(1));
				previousAverage = period.previous.map((datum, index) => datum.weather[datum.weatherKeys[0]]?.precipitation).reduce((a, b) => a + b, 0) / period.previous.length;
				break;

			case 'WaterComponents':
				// period.current = period.current.map((el) => {
				// 	return {
				// 		...el,
				// 		Clouds: el.Clouds || 0,
				// 		SS: el.SS || 0,
				// 		SML: el.SML || 0,
				// 		CDOM: el.CDOM || 0,
				// 		BloomSpread: el.BloomSpread || 0,
				// 	};
				// });
				currentValues = {
					Clouds: period.current.map((datum) => datum.Clouds || 0),
					SS: period.current.map((datum) => datum.SS || 0),
					SML: period.current.map((datum) => datum.SML || 0),
					CDOM: period.current.map((datum) => datum.CDOM || 0),
					BloomSpread: period.current.map((datum) => datum.BloomSpread || 0),
				};

				period.max[trendType.key] = {
					Clouds: Number(Math.max(...currentValues.Clouds).toFixed(1)),
					SS: Number(Math.max(...currentValues.SS).toFixed(1)),
					SML: Number(Math.max(...currentValues.SML).toFixed(1)),
					CDOM: Number(Math.max(...currentValues.CDOM).toFixed(1)),
					BloomSpread: Number(Math.max(...currentValues.BloomSpread).toFixed(1)),
				};

				period.min[trendType.key] = {
					Clouds: Number(Math.min(...currentValues.Clouds).toFixed(1)),
					SS: Number(Math.min(...currentValues.SS).toFixed(1)),
					SML: Number(Math.min(...currentValues.SML).toFixed(1)),
					CDOM: Number(Math.min(...currentValues.CDOM).toFixed(1)),
					BloomSpread: Number(Math.min(...currentValues.BloomSpread).toFixed(1)),
				};

				/*period.mean[trendType.key] = {
                    Clouds: Number(_.mean(...currentValues.Clouds).toFixed(1)),
                    SS: Number(_.mean(...currentValues.SS).toFixed(1)),
                    SML: Number(_.mean(...currentValues.SML).toFixed(1)),
                    CDOM: Number(_.mean(...currentValues.CDOM).toFixed(1)),
                    BloomLevel: Number(_.mean(...currentValues.BloomSpread).toFixed(1)),
                };*/

				previousAverage = {
					Clouds: period.previous.map((datum) => datum.Clouds).reduce((a, b) => a + b, 0) / period.previous.length,
					SS: period.previous.map((datum) => datum.SS).reduce((a, b) => a + b, 0) / period.previous.length,
					SML: period.previous.map((datum) => datum.SML).reduce((a, b) => a + b, 0) / period.previous.length,
					CDOM: period.previous.map((datum) => datum.CDOM).reduce((a, b) => a + b, 0) / period.previous.length,
					BloomSpread: period.previous.map((datum) => datum.BloomSpread).reduce((a, b) => a + b, 0) / period.previous.length,
				};
				break;

			default:
				currentValues = period.current.map((datum) => datum[trendType.key]);
				previousAverage = period.previous.map((datum) => datum[trendType.key]).reduce((a, b) => a + b, 0) / period.previous.length;
				period.max[trendType.key] = Number(Math.max(...currentValues).toFixed(_.includes(['BloomLevel'], trendType.key) ? Constants.UNITS[context.units][trendType.key].decimals : 1));
				period.min[trendType.key] = Number(Math.min(...currentValues).toFixed(_.includes(['BloomLevel'], trendType.key) ? Constants.UNITS[context.units][trendType.key].decimals : 1));
				//period.mean[trendType.key] = Number(_.mean(...currentValues).toFixed(_.includes(['BloomLevel'], trendType.key) ? Constants.UNITS[   context.units][trendType.key].decimals : 1));
				break;
		}

		if (trendType.key !== 'WaterComponents') {
			averageValue = currentValues.reduce((a, b) => a + b, 0) / period.current.length;
			delta = previousAverage > 0 ? ((averageValue - previousAverage) / previousAverage) * 100 : 0;

			period.averages[trendType.key] = Number(averageValue.toFixed(_.includes(['BloomLevel', 'Temp', 'Wind', 'Precipitation', 'Carbon'], trendType.key) ? Constants.UNITS[context.units][trendType.key].decimals : 1));
			period.averages[`${trendType.key}DependentAxisLabels`] =
				trendType.key === 'UV' ? [0, 5, 10] : trendType.key === 'Visibility' ? [0, 50, 100] : trendType.key === 'Precipitation' ? [Number(period.min[trendType.key].toFixed(2)), Number((period.max[trendType.key] - period.min[trendType.key]).toFixed(2)), Number(period.max[trendType.key].toFixed(2))] : undefined;
			period.averages[`${trendType.key}Annotation`] = _.includes(['BloomLevel', 'Temp', 'Wind', 'Precipitation', 'Carbon'], trendType.key) ? Constants.UNITS[context.units][trendType.key].label : trendType.key !== 'UV' ? '%' : '';
			period.averages[`${trendType.key}Delta`] = `${delta >= 0 ? Constants.UP : Constants.DOWN} ${Math.abs(delta).toFixed(_.includes(['BloomLevel', 'Temp', 'Wind', 'Precipitation', 'Carbon'], trendType.key) ? Constants.UNITS[context.units][trendType.key].decimals : 1)}`;
		} else {
			averageValue = {
				Clouds: currentValues.Clouds.reduce((a, b) => a + b, 0) / period.current.length,
				SS: currentValues.SS.reduce((a, b) => a + b, 0) / period.current.length,
				SML: currentValues.SML.reduce((a, b) => a + b, 0) / period.current.length,
				CDOM: currentValues.CDOM.reduce((a, b) => a + b, 0) / period.current.length,
				BloomSpread: currentValues.BloomSpread.reduce((a, b) => a + b, 0) / period.current.length,
			};
			delta = {
				Clouds: previousAverage > 0 ? ((previousAverage.Clouds - averageValue.Clouds) / previousAverage.Clouds) * 100 : 0,
				SS: previousAverage > 0 ? ((previousAverage.SS - averageValue.SS) / previousAverage.SS) * 100 : 0,
				SML: previousAverage > 0 ? ((previousAverage.SML - averageValue.SML) / previousAverage.SML) * 100 : 0,
				CDOM: previousAverage > 0 ? ((previousAverage.CDOM - averageValue.CDOM) / previousAverage.CDOM) * 100 : 0,
				BloomSpread: previousAverage > 0 ? ((previousAverage.BloomSpread - averageValue.BloomSpread) / previousAverage.BloomSpread) * 100 : 0,
			};
			period.averages.Clouds = Number(averageValue.Clouds.toFixed(1));
			period.averages.SS = Number(averageValue.SS.toFixed(1));
			period.averages.SML = Number(averageValue.SML.toFixed(1));
			period.averages.CDOM = Number(averageValue.CDOM.toFixed(1));
			period.averages.BloomSpread = Number(averageValue.BloomSpread.toFixed(1));
			period.averages.CloudsAnnotation = '%';
			period.averages.SSAnnotation = '%';
			period.averages.SMLAnnotation = '%';
			period.averages.CDOMAnnotation = '%';
			period.averages.BloomSpreadAnnotation = Constants.UNITS[context.units].BloomSpread.label;
			period.averages.CloudsDelta = `${delta.Clouds >= 0 ? Constants.UP : Constants.DOWN} ${Math.abs(delta.Clouds).toFixed(1)}`;
			period.averages.SSDelta = `${delta.SS >= 0 ? Constants.UP : Constants.DOWN} ${Math.abs(delta.SS).toFixed(1)}`;
			period.averages.SMLDelta = `${delta.SML >= 0 ? Constants.UP : Constants.DOWN} ${Math.abs(delta.SML).toFixed(1)}`;
			period.averages.CDOMDelta = `${delta.CDOM >= 0 ? Constants.UP : Constants.DOWN} ${Math.abs(delta.CDOM).toFixed(1)}`;
			period.averages.BloomSpreadDelta = `${delta.BloomSpread >= 0 ? Constants.UP : Constants.DOWN} ${Math.abs(delta.BloomSpread).toFixed(1)}`;
		}
	}

	return period;
};

export const adjustForDataGaps = (gappedTrendType, estimate, current) => {
	let response = [],
		data = _.cloneDeep(current),
		trendTypes,
		{ bloomTolerance, cloudsTolerance } = Constants.TREND_TYPES.find((_trendType) => _trendType.key === 'BloomLevel');

	if (gappedTrendType === 'waterComponents') {
		trendTypes = ['BloomLevel', 'BloomSpread', 'CDOM', 'SML', 'SS', 'Clouds'];
	} else {
		trendTypes = [gappedTrendType];
	}

	trendTypes.forEach((trendTypeParam) => {
		data.forEach((value) => {
			if (isBlank(value) || isBlank(value[trendTypeParam]) || isNaN(value[trendTypeParam]) || value.Clouds === 100 || (value.BloomSpread <= bloomTolerance && value.Clouds > cloudsTolerance) || Math.ceil(value.BGI.Bloom + value.BGI.CDOM + value.BGI.SML + value.BGI.SS + value.BGI.Clouds) < 1) {
				value.estimate = true;
			}
		});
	});

	response = data.sort((a, b) => (a.date < b.date ? -1 : 1));

	if (estimate) {
		let reversed = _.cloneDeep(response).reverse();

		response.forEach((_response, _index) => {
			if (_response.estimate) {
				let lastIndex = response.length - 1,
					nextValidIndex = response.findIndex((__response, _responseIndex) => _responseIndex > _index && !__response.estimate),
					reversedIndex = lastIndex - _index,
					previousValidIndex = _index > 0 ? reversed.findIndex((__response, _responseIndex) => _responseIndex > reversedIndex && !__response.estimate) : -1; // -1 required because findIndex is 0 based

				if (previousValidIndex >= 0) {
					previousValidIndex = lastIndex - previousValidIndex;
				}

				trendTypes.forEach((trendTypeParam) => {
					// set these here to ensure that they are set for all trendTypes
					response[_index].estimate = true;
					response[_index].estimated = false;
					reversed[reversedIndex].estimate = true;
					reversed[reversedIndex].estimated = false;

					if (previousValidIndex >= 0 && nextValidIndex >= 0) {
						let delta = response[nextValidIndex][trendTypeParam] - response[previousValidIndex][trendTypeParam],
							deltaDays = nextValidIndex - previousValidIndex,
							dayDelta = delta / deltaDays;

						for (let deltaDay = 0; deltaDay < deltaDays - 1; deltaDay++) {
							response[_index + deltaDay][trendTypeParam] = Number(Math.ceil(response[previousValidIndex][trendTypeParam] + dayDelta * (deltaDay + 1)).toFixed(1));
							reversed[reversedIndex - deltaDay][trendTypeParam] = response[_index + deltaDay][trendTypeParam];

							response[_index + deltaDay].estimate = false;
							response[_index + deltaDay].estimated = true;
							reversed[reversedIndex - deltaDay].estimate = false;
							reversed[reversedIndex - deltaDay].estimated = true;
						}
					} else if (previousValidIndex >= 0) {
						response[_index][trendTypeParam] = response[previousValidIndex][trendTypeParam];
						reversed[reversedIndex][trendTypeParam] = response[_index][trendTypeParam];

						response[_index].estimate = false;
						response[_index].estimated = true;
						reversed[reversedIndex].estimate = false;
						reversed[reversedIndex].estimated = true;
					} else if (nextValidIndex >= 0) {
						response[_index][trendTypeParam] = response[nextValidIndex][trendTypeParam];
						reversed[reversedIndex][trendTypeParam] = response[_index][trendTypeParam];

						response[_index].estimate = false;
						response[_index].estimated = true;
						reversed[reversedIndex].estimate = false;
						reversed[reversedIndex].estimated = true;
					}
				});
			}
		});
	}

	return response;
};

export const getTrendsDataFromImageries = (stateCopy) => {
	// let state = { ...stateCopy };
	let trends = {};
	trends[SATELLITES.copernicus] = [];
	trends[SATELLITES.planet] = [];

	let offset = new Date().getTimezoneOffset() / 60; // Time zone difference in hours.

	if (!stateCopy || !stateCopy.location || !stateCopy.location.imagery) return trends;

	let imageries = stateCopy.location.imagery;

	let element = {};
	for (let imageryIndex = imageries.length - 1; imageryIndex >= 0; imageryIndex--) {
		if (imageries[imageryIndex][SATELLITES.copernicus] && imageries[imageryIndex][SATELLITES.copernicus].trendData) {
			element = imageries[imageryIndex][SATELLITES.copernicus].trendData;
			let date = offset >= 0 ? subHours(new Date(element.date), offset) : addHours(new Date(element.date), Math.abs(offset));
			trends[SATELLITES.copernicus].push({
				...element,
				date: date,
				Carbon: element.Carbon,
			});
		}
		// if (imageries[imageryIndex][SATELLITES.planet] && imageries[imageryIndex][SATELLITES.planet].trendData) {
		// 	element = imageries[imageryIndex][SATELLITES.planet].trendData;
		// 	let copyDate = imageries[imageryIndex].date;
		// 	copyDate = dayjs(copyDate);
		// 	copyDate = copyDate['$d'];

		// 	trends[SATELLITES.planet].push({
		// 		...element,
		// 		date: offset >= 0 ? subHours(new Date(copyDate), offset) : addHours(new Date(copyDate), Math.abs(offset)),
		// 		Carbon: element.Carbon,
		// 	});
		// }
	}

	imageries = stateCopy.planetImagery;
	if (imageries) {
		for (let imageryIndex = imageries.length - 1; imageryIndex >= 0; imageryIndex--) {
			if (imageries[imageryIndex][SATELLITES.planet] && imageries[imageryIndex][SATELLITES.planet].trendData) {
				element = imageries[imageryIndex][SATELLITES.planet].trendData;
				let copyDate = imageries[imageryIndex].date;
				copyDate = dayjs(copyDate);
				copyDate = copyDate['$d'];

				trends[SATELLITES.planet].push({
					...element,
					date: offset >= 0 ? subHours(new Date(copyDate), offset) : addHours(new Date(copyDate), Math.abs(offset)),
					Carbon: element.Carbon,
				});
			}
		}
	} else {
		stateCopy.planetImagery = undefined;
	}

	return trends;
};

export const getWindDirection = (windDirection) => {
	let response = '';
	if ((windDirection >= 337.5 && windDirection <= 360) || windDirection >= 0 || windDirection < 22.5) {
		response = 'N';
	} else if (windDirection >= 22.5 && windDirection < 67.5) {
		response = 'NE';
	} else if (windDirection >= 67.5 && windDirection < 112.5) {
		response = 'E';
	} else if (windDirection >= 112.5 && windDirection < 157.5) {
		response = 'SE';
	} else if (windDirection >= 157.5 && windDirection < 202.5) {
		response = 'S';
	} else if (windDirection >= 202.5 && windDirection < 247.5) {
		response = 'SW';
	} else if (windDirection >= 247.5 && windDirection < 292.5) {
		response = 'W';
	} else {
		response = 'NW';
	}

	return response;
};

export const getBloomPredictedData = (trendData) => {
	let firstPredictedDay = {
		BGI: {
			Bloom: 0,
			CDOM: 0,
			Clouds: 0,
			SML: 0,
			SS: 0,
		},
		BloomLevel: 0,
		BloomSpread: 0,
		CDOM: 0,
		Carbon: 0,
		Clouds: 0,
		SML: 0,
		SS: 0,
		Visibility: 0,
		date: new Date(),
		weather: {},
		weatherKeys: [],
		predicted: true,
		estimated: true,
		estimate: true,
	};
	let secondPredictedDay = {
		BGI: {
			Bloom: 0,
			CDOM: 0,
			Clouds: 0,
			SML: 0,
			SS: 0,
		},
		BloomLevel: 0,
		BloomSpread: 0,
		CDOM: 0,
		Carbon: 0,
		Clouds: 0,
		SML: 0,
		SS: 0,
		Visibility: 0,
		date: new Date(),
		weather: {},
		weatherKeys: [],
		predicted: true,
		estimated: true,
		estimate: true,
	};
	let result = [];

	if (trendData && trendData.length) {
		let element = trendData[trendData.length - 1];

		//update date values
		firstPredictedDay.date = addDays(element.date, { days: 1 });
		secondPredictedDay.date = addDays(firstPredictedDay.date, { days: 1 });

		// update bloom values
		firstPredictedDay.BloomLevel = element.BloomLevel;
		secondPredictedDay.BloomLevel = element.BloomLevel;

		firstPredictedDay.BloomSpread = element.BloomSpread;
		secondPredictedDay.BloomSpread = element.BloomSpread;

		//update weather and weatherKeys values
		if (element.weatherKeys && element.weatherKeys.length) {
			firstPredictedDay.weatherKeys = [...element.weatherKeys];
			secondPredictedDay.weatherKeys = [...element.weatherKeys];

			firstPredictedDay.weather[firstPredictedDay.weatherKeys[0]] = {
				cloudcover: 0,
				cloudcover_high: 0,
				cloudcover_low: 0,
				cloudcover_max: 0,
				cloudcover_mean: 0,
				cloudcover_mid: 0,
				cloudcover_min: 0,
				precipitation: 0,
				rain: 0,
				showers: 'N/A',
				snowfall: 'N/A',
				temperature_2m: 18.7,
				temperature_2m_max: 18.7,
				temperature_2m_mean: 14.30625,
				temperature_2m_min: 12.5,
				uv_index: 'N/A',
				weathercode: 0,
				winddirection_10m: 211,
				windgusts_10m: 23,
				windspeed_10m: 9.2,
				windspeed_10m_max: 12.9,
				windspeed_10m_mean: 7.918749999999999,
				windspeed_10m_min: 3,
			};
			secondPredictedDay.weather[firstPredictedDay.weatherKeys[0]] = firstPredictedDay.weather[firstPredictedDay.weatherKeys[0]];
		}

		result = [firstPredictedDay, secondPredictedDay];
		result = []; //delete this when  you want preditions
	}

	return result;
};
