import React from 'react';

import dayjs from 'dayjs';
import { Constants, PRO_USER_DEFAULT_LAYERS, SATELLITES, STANDARD_USER_DEFAULT_LAYERS } from '../../Constants';
import { format } from 'date-fns';

export const getSelectedDateValueService = (flag, date, satellite, selectedDate, defaultDate, selectedLocation, planetImagery) => {
	// export 	const getSelectedDateValue = ({ flag, date = undefined, satellite = satelliteView }) => {
	if (!selectedDate) {
		return '';
	}
	let satelliteViewCopy = satellite;
	let currentDate = selectedDate ? selectedDate : defaultDate;
	currentDate = date ? date['$d'] : currentDate['$d'];
	currentDate = format(currentDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	let key = satelliteViewCopy;
	let imageries = satelliteViewCopy === SATELLITES.copernicus ? selectedLocation.imagery : planetImagery;
	let datesArray = selectedLocation && selectedLocation.satellites && selectedLocation.satellites[key] && selectedLocation.satellites[key].dates && selectedLocation.satellites[key].dates.length ? selectedLocation.satellites[key].dates : undefined;
	if (datesArray) {
		let resultDate;
		let index = datesArray.findIndex((el) => el === currentDate);
		if (index >= 0 && index < datesArray.length) {
			if (flag > 0) {
				resultDate = datesArray[index - 1];
			} else {
				resultDate = datesArray[index + 1];
			}
			//find result date trends data from location imagery and return it same as tooltip for dates
			if (resultDate && imageries && imageries.length > 0) {
				let imagery = imageries.findIndex((el) => el.date === resultDate); //index
				imagery = imageries[imagery]; // imagery value
				// create tooltip text and return it
				let data = imagery ? imagery[satelliteViewCopy] : {};
				let dateToDisplay = imagery ? dayjs(imagery.date) : '';
				dateToDisplay = dateToDisplay['$d'];
				if (imagery) {
					return (
						<span>
							Move to : {dateToDisplay?.toLocaleDateString()}
							<br />
							{/* {'Sentinel 3:'}
                            <br /> */}
							{selectedLocation?.saltWater ? 'Bloom Level (max)' : 'Bloom Level'} :{data?.trendData ? (Math.round((data.trendData?.BloomLevel || 0) * 100) / 100).toFixed(2) : 0}
							<br />
							Bloom Spread :{data?.trendData ? (Math.round((data.trendData?.BloomSpread || 0) * 100) / 100).toFixed(2) : 0}
							{'%'}
							<br />
							Clouds: {data?.trendData ? (Math.round((data.trendData?.Clouds || 0) * 100) / 100).toFixed(2) : 0}
							{'%'}
							{/* {planetTooltip} */}
						</span>
					);
				}
				return '';
			}
		}
	}
};

export const onDayChangeService = async (data, view, setSelectedMonth, setSelectedDate, setStartMonthFilter, selectedLayers, showLayersAfterDateChange, setNextDateTooltip, setPrevDateTooltip, getSelectedDateValue) => {
	if (!data) return;

	let modifiedDate = format(data['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	modifiedDate = dayjs(modifiedDate);

	setSelectedMonth(modifiedDate);
	setSelectedDate(data);
	let state = await setStartMonthFilter(modifiedDate['$d']);

	if (selectedLayers.length > 0) {
		let newDate = modifiedDate['$d'];
		let viewCopy = view === SATELLITES.copernicus ? SATELLITES.copernicus : view === SATELLITES.planet ? SATELLITES.planet : '';
		showLayersAfterDateChange(newDate, viewCopy, state);
		setNextDateTooltip(getSelectedDateValue(1, modifiedDate, viewCopy));
		setPrevDateTooltip(getSelectedDateValue(-1, modifiedDate, viewCopy));
	}
};

export const handleLocationChangeService = async (data, selectedLocation, options, setSelectedLayers, setLocation, setShowDefaultLayers, setStartMonthFilter, selectedDate, defaultDate, isProUser) => {
	if (!data) {
		return;
	}
	if (selectedLocation?.locationsId !== data.locationsId) {
		const index = options.findIndex((el) => el.locationsId === data.locationsId);
		if (index >= 0 && options[index]) {
			setSelectedLayers(isProUser ? PRO_USER_DEFAULT_LAYERS : STANDARD_USER_DEFAULT_LAYERS);
			await setLocation(data.locationsId);
			setShowDefaultLayers(true);
			await setStartMonthFilter(selectedDate ? selectedDate['$d'] : defaultDate['$d']);
		}
	}
};

export const shouldDisableDateService = (date, satelliteView, selectedLocation) => {
	if (!date) return;
	let formattedDate = format(date['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);

	if (satelliteView === SATELLITES.copernicus) {
		//check valid dates in the selectedLocation for sentinel
		// return images[formattedDate]?.thumbnails['trueColour'].caption;
		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites[satelliteView] && selectedLocation.satellites[satelliteView].dates) {
			let index = selectedLocation.satellites[satelliteView].dates.indexOf(formattedDate);
			return index >= 0 ? false : true;
		}
	} else if (satelliteView === SATELLITES.planet) {
		//check valid dates in the selectedLocation for planet

		if (selectedLocation && selectedLocation.satellites && selectedLocation.satellites[satelliteView] && selectedLocation.satellites[satelliteView].dates) {
			let index = selectedLocation.satellites[satelliteView].dates.indexOf(formattedDate);
			return index >= 0 ? false : true;
		}

		// if (images[formattedDate] && images[formattedDate].imagery && images[formattedDate].imagery.urls) {
		//  return images[formattedDate].imagery.urls.planet_url ? false : true;
		// } else {
		//  return true;
		// }
	} else {
		return;
	}
};
