import React from 'react';
import './index.css';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Tab, Tabs } from '@mui/material';

export default function BodySection({ bloomSpreadAverage, bloomLevelAverage, carbonAverage, bloomSpreadAnnotation, bloomLevelAnnotation, carbonAnnotation, bloomLevelDelta, bloomSpreadDelta, bloomLevelDirection, bloomSpreadDirection, carbonDelta, carbonDirection, setTrendType, user, location }) {
	const BloomLevelDeltaIcon = bloomLevelDirection === 'up' ? ArrowUpwardOutlinedIcon : ArrowDownwardIcon;
	const BloomSpreadDeltaIcon = bloomSpreadDirection === 'up' ? ArrowUpwardOutlinedIcon : ArrowDownwardIcon;
	const CarbonDeltaIcon = carbonDirection === 'up' ? ArrowUpwardOutlinedIcon : ArrowDownwardIcon;
	const avgBloomSpead = (
		<div className="avg-bloom">
			<span className="bloom-title">{'Bloom Spread'}</span>
			<span className="percentage">
				<span style={{ fontFamily: 'Inter', fontSize: '20px', fontWeight: '700' }}>{bloomSpreadAverage}</span> <span style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '400' }}> {bloomSpreadAnnotation}</span>
			</span>

			<span className="percentage" style={{ fontSize: '12px', fontWeight: '400', letterSpacing: '0' }}>
				<BloomSpreadDeltaIcon style={{ height: '14px', width: '14px' }} /> <span>{bloomSpreadDelta}</span>
			</span>
		</div>
	);

	const avgBloomLevel = (
		<div className="avg-bloom">
			<span className="bloom-title"> {location?.saltWater ? 'Bloom Level (max)' : 'Bloom Level'}</span>
			<span className="percentage" style={{ gap: '2px' }}>
				<span style={{ fontFamily: 'Inter', fontSize: '22px', fontWeight: '700' }}>{bloomLevelAverage}</span> <span style={{ fontWeight: '400', color: 'rgba(0, 0, 0, 0.50)', textTransform: 'lowercase' }}> {bloomLevelAnnotation} </span>
			</span>

			<span className="percentage" style={{ fontSize: '12px', fontWeight: '400', letterSpacing: '0' }}>
				<BloomLevelDeltaIcon style={{ height: '14px', width: '14px', color: 'rgba(0, 0, 0, 0.50)' }} />
				<span style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '400' }}> {bloomLevelDelta}</span>
			</span>
		</div>
	);

	const avgCarbon = (
		<div className="avg-bloom">
			<span className="bloom-title">{'Carbon'}</span>
			<span className="percentage" style={{ gap: '2px' }}>
				<span style={{ fontFamily: 'Inter', fontSize: '22px', fontWeight: '700' }}>{carbonAverage}</span> <span style={{ fontWeight: '400', color: 'rgba(0, 0, 0, 0.50)', textTransform: 'lowercase' }}> {carbonAnnotation} </span>
			</span>

			<span className="percentage" style={{ fontSize: '12px', fontWeight: '400', letterSpacing: '0' }}>
				<CarbonDeltaIcon style={{ height: '14px', width: '14px', color: 'rgba(0, 0, 0, 0.50)' }} />
				<span style={{ fontFamily: 'Inter', fontSize: '12px', fontWeight: '400' }}> {carbonDelta}</span>
			</span>
		</div>
	);

	const [value, setValue] = React.useState('BloomLevel');

	const handleChange = (event, newValue) => {
		setValue(newValue);
		setTrendType(newValue);
	};

	return (
		<div className="body-section">
			<Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example" className="tabs-container">
				<Tab value="BloomLevel" label={avgBloomLevel} className="tab" />
				<Tab value="BloomSpread" label={avgBloomSpead} wrapped className="tab" />
				{user.groups.includes('carbon') && <Tab value="Carbon" label={avgCarbon} wrapped className="tab" />}

				{/* {context.user.groups.includes('carbon') && <Tab value="BloomSpread" label={'Carbon'} wrapped className="tab" />} */}
			</Tabs>
		</div>
	);
}
