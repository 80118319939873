import React from 'react';
import './index.css';

export default function FreshWaterLegend() {
	let values = ['0', '25', '75', '350+'];
	const legendTitle = 'Bloom levels:';
	const clouds = 'Clouds';
	const interfernces = 'Interferences';

	return (
		<div className="legend-container">
			<div className="legend-inner-container">
				<span className="legend-text" style={{ marginTop: '14px' }}>
					{' '}
					{legendTitle}{' '}
				</span>

				<div style={{ height: '8px', width: '221px', marginLeft: '14px', marginRight: '14px' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '23px' }}>
						{values.map((el) => (
							<span key={el + el} style={{ textAlign: 'center', fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: 400, lineHeight: 'normal' }}>
								{el}
							</span>
						))}
					</div>

					<div
						style={{
							width: '8px',
							height: '221px',
							transform: 'rotate(90deg)',
							transformOrigin: 'top left',
							top: '14px',
							position: 'absolute',
							marginLeft: '221px',
						}}
						className="colours"></div>
				</div>

				<div className="legend-divider-new">
					<svg xmlns="http://www.w3.org/2000/svg" width="2" height="42" viewBox="0 0 2 42" fill="none">
						<path d="M1 -0.5V42" stroke="white" strokeOpacity="0.3" />
					</svg>
				</div>

				<div style={{ marginTop: '14px', marginLeft: '12px', display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
					<svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none" style={{ marginTop: '2px' }}>
						<g clipPath="url(#clip0_4050_82651)">
							<rect width="10" height="10" transform="translate(0 0.5)" fill="#888888" />
							<path d="M4 -0.5L-6 9.5" stroke="#D2D2D2" />
							<path d="M8 -0.5L-4.5 12" stroke="#D2D2D2" />
							<path d="M12 -0.5L-0.5 12" stroke="#D2D2D2" />
							<path d="M16 -0.5L3.5 12" stroke="#D2D2D2" />
							<path d="M20 -0.5L7.5 12" stroke="#D2D2D2" />
						</g>
						<defs>
							<clipPath id="clip0_4050_82651">
								<rect width="10" height="10" fill="white" transform="translate(0 0.5)" />
							</clipPath>
						</defs>
					</svg>
					<div className="legend-text" style={{ marginLeft: '7px' }}>
						{' '}
						{clouds}{' '}
					</div>

					<div style={{ height: '10px', width: '10px', background: '#7A806C', marginLeft: '7px', marginTop: '2px' }}></div>

					<div className="legend-text" style={{ marginLeft: '7px' }}>
						{' '}
						{interfernces}{' '}
					</div>
				</div>
			</div>
		</div>
	);
}
