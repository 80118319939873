import React, { useEffect, useRef, useState } from 'react';
import './index.css';
import BodySection from './components/bodySection/BodySection';
import BottomSection from './components/bottomSection/BottomSection';
import MainSection from './components/mainSection/MainSection';
import { MenuItem, Select, Typography } from '@mui/material';
import { ALERTS_ALL_ZONES_FILTER, ALERTS_READ_STATUS, ALERTS_TIME_DEFAULT_FILTER, ALERTS_UNREAD_STATUS, Constants, PULL_ALERTS_TIMER, SATELLITES, TRENDS_ALERTS_TAB, TRENDS_WATER_COMPONENTS_TAB } from '../Constants';
import { getBloomPredictedData, getValues } from '../../services/trendsServices';
import { differenceInCalendarYears, format } from 'date-fns';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LakeInfo from './components/lakeInfo/LakeInfo';
import WaterComponents from './components/waterComponents/WaterComponents';
import dayjs from 'dayjs';
// import { getSatelliteParamName } from '../../services/mapboxServices';
import { Tab, Tabs } from '@mui/material';
import AlertsList from './components/alertsList/AlertsList';
import AlertsFilters from './components/alertsFilters/AlertsFilters';
import { filterAlerts, getAlertsDataFromNotifications, getAlertsNumberColour, getTimeFilters, getZonesList, readAllAlertsService } from '../../services/alertsServices';
import { fetch } from '../Services';

export default function MainTrends(props) {
	const trendsTitle = 'Water body trends';
	const alertsTitle = 'Alerts';
	const defaultTrendType = 'BloomLevel';
	const defaultWeatherType = 'Temp';
	const { selectedDate, isTablet, location, user, collapseTrends, userMode, satelliteView, trendDataForAllSatellites, setSelectedDate, showLayersAfterDateChange } = props.data;
	const [alertsNumber, setAlertsNumber] = useState(0);
	const [alertsNumberColour, setAlertsNumberColour] = useState('transparent');

	let context = props.data;
	const isSaltWater = location?.saltWater || false;
	let trendData = [];
	let [dateRange, setDateRangeState] = useState(Constants.TREND_DATE_RANGES[0]);
	const [selectedTab, setSelectedTab] = useState(TRENDS_WATER_COMPONENTS_TAB);

	const [alerts, setAlerts] = useState();

	const showAlerts = true;
	let [trendType, setTrendType] = useState(defaultTrendType);

	let [weatherType, setWeatherType] = useState(defaultWeatherType);

	let [dateRanges, setDateRangesState] = useState(Constants.TREND_DATE_RANGES);

	const [headerData, setHeaderData] = useState(null);
	const [alertsFilter, setAlertsFilter] = useState({ time: ALERTS_TIME_DEFAULT_FILTER, zone: ALERTS_ALL_ZONES_FILTER });

	if (context.trendData) {
		trendData = trendDataForAllSatellites ? trendDataForAllSatellites[satelliteView] : [];
	}
	const bloomPredictedData = getBloomPredictedData(trendData);

	let period = getValues([...trendData], dateRange, context);
	// let predictedPeriod = getValues([...trendData, ...bloomPredictedData], dateRange, context);

	const previousDate = useRef(null);

	useEffect(() => {
		let modifiedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		modifiedDate = dayjs(modifiedDate);
		modifiedDate = modifiedDate['$d'];

		if (trendData && trendData.length > 0 && modifiedDate && previousDate.current !== modifiedDate) {
			let formattedDate = format(modifiedDate, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
			let index = trendData.findIndex((data) => {
				if (data && data.date) {
					let formatGivenDate = format(data.date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
					return formatGivenDate === formattedDate;
				} else {
					return undefined;
				}
			});

			previousDate.current = modifiedDate['$d'];
			if (index >= 0) {
				setHeaderData(trendData[index]); // header data should get its data from imagery[satelliteView].trendData
			}
		}
	}, [selectedDate, trendData]);
	// let imagery;
	// let satelliteKey = getSatelliteParamName(satteLiteView)
	// if(images && Object.keys(images) && Object.keys(images).length > 0 ){
	// 	let formatSelectedDate = format(selectedDate['$d'], Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
	// 	imagery = images[formatSelectedDate].imagery;
	// 	if(imagery && imagery[satelliteKey] && imagery[satelliteKey].trendData ){
	// 		setHeaderData(imagery[satelliteKey].trendData);// header data should get its data from imagery[satelliteView].trendData
	// 	}
	// }

	useEffect(() => {
		//initial pull alerts request
		if (!alerts && showAlerts) {
			fetch({
				url: `getNotifications`,
				data: {},
			})
				.then(async (response) => {
					let alertsFromNotifications = getAlertsDataFromNotifications([...response], location);
					alertsFromNotifications.sort(compareDates);

					let number = 0;
					for (const alert of alertsFromNotifications) {
						if (alert.value.status === ALERTS_UNREAD_STATUS) {
							number++;
						}
					}
					setAlertsNumber(number);
					const colour = getAlertsNumberColour(alertsFromNotifications);
					setAlerts(alertsFromNotifications);

					setAlertsNumberColour(colour);
				})
				.catch((error) => {
					console.log({ error });
				});
		}
		const interval = setInterval(() => {
			if (showAlerts) {
				//fetch alerts

				let data = {};
				if (alerts && alerts.length > 0) {
					data = { lastNotificationsId: alerts[alerts.length - 1].value.notificationsId };
				}
				fetch({
					url: `getNotifications`,
					data: data,
				}).then(async (response) => {
					if (response?.length > 0) {
						const filteredResponse = getAlertsDataFromNotifications(response, location);
						const newAlerts = [...alerts, ...filteredResponse];
						let number = 0;
						for (const alert of newAlerts) {
							if (alert.value.status === ALERTS_UNREAD_STATUS) {
								number++;
							}
						}
						setAlerts(newAlerts);
						setAlertsNumber(number);
					}
				});
			}
		}, PULL_ALERTS_TIMER);
		return () => clearInterval(interval);
	}, [alerts, showAlerts, location]);

	function toFixed(x, key) {
		return Number.parseFloat(x).toFixed(Constants.UNITS[context.user.units][key].decimals);
	}

	const bloomSpreadAverage = period.averages['BloomSpread'];
	const bloomLevelAverage = period.averages['BloomLevel'];
	const bloomSpreadAnnotation = period.averages[`BloomSpreadAnnotation`];
	const bloomLevelAnnotation = period.averages[`BloomLevelAnnotation`];

	const carbonAverage = period.averages['Carbon'];
	const carbonAnnotation = period.averages[`CarbonAnnotation`];

	let bloomLevelDelta = period.averages[`BloomLevelDelta`];
	let bloomLevelDirection = '';
	let bloomSpreadDelta = period.averages[`BloomSpreadDelta`];
	let bloomSpreadDirection = '';

	let carbonDelta = period.averages[`CarbonDelta`];
	let carbonDirection = '';

	const weatherValues = headerData ? headerData.weather[headerData.weatherKeys[0]] : '';
	let tempratureMin = null;
	let tempratureMax = null;

	if (headerData && weatherValues && weatherValues.temperature_2m_min) {
		tempratureMin = weatherValues.temperature_2m_min;
	}
	if (headerData && weatherValues && weatherValues.temperature_2m_max) {
		tempratureMax = weatherValues.temperature_2m_max;
	}

	const showAverageRange = tempratureMin !== undefined && tempratureMin !== null && tempratureMax !== undefined && tempratureMax !== null;

	const mainSectionProps = {
		windSpeed: headerData ? weatherValues?.windspeed_10m : '',
		bloomLevel: headerData ? toFixed(headerData?.BloomLevel, 'BloomLevel') : '',
		bloomSpread: headerData ? toFixed(headerData?.BloomSpread, 'BloomSpread') : '',
		selectedDate: selectedDate,
		user: context.user,
		bloomLevelAnnotation: bloomLevelAnnotation,
		wc: headerData && weatherValues ? weatherValues?.weathercode : 0,
		windDirection: headerData && weatherValues ? headerData && weatherValues?.winddirection_10m : '',
		precip: headerData && weatherValues ? headerData && weatherValues?.precipitation : '',
		isTablet: isTablet,
		showAverageRange,
		location: location,
	};

	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.UP)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.UP, '');
		bloomLevelDirection = 'up';
	}
	if (bloomLevelDelta && bloomLevelDelta.includes(Constants.DOWN)) {
		bloomLevelDelta = bloomLevelDelta.replace(Constants.DOWN, '');
		bloomLevelDirection = 'down';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.UP)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.UP, '');
		bloomSpreadDirection = 'up';
	}
	if (bloomSpreadDelta && bloomSpreadDelta.includes(Constants.DOWN)) {
		bloomSpreadDelta = bloomSpreadDelta.replace(Constants.DOWN, '');
		bloomSpreadDirection = 'down';
	}

	if (carbonDelta && carbonDelta.includes(Constants.UP)) {
		carbonDelta = carbonDelta.replace(Constants.UP, '');
		carbonDirection = 'up';
	}
	if (carbonDelta && carbonDelta.includes(Constants.DOWN)) {
		carbonDelta = carbonDelta.replace(Constants.DOWN, '');
		carbonDirection = 'down';
	}

	const bodyProps = { bloomSpreadAverage, bloomLevelAverage, carbonAverage, bloomSpreadAnnotation, bloomLevelAnnotation, carbonAnnotation, bloomLevelDelta, bloomSpreadDelta, bloomLevelDirection, bloomSpreadDirection, carbonDelta, carbonDirection, setTrendType, setDateRangeState, user: user, location: location };

	const bottomProps = {
		dateRange,
		current: period.current,
		trendType: trendType,
		period,
		weatherType,
		context,
		setWeatherType,
		trendData: trendData,
		dateRanges: dateRanges,
		isTablet: isTablet,
		bloomPredictedData: bloomPredictedData,
		location: location,
	};

	const setDateRange = (event) => {
		let value = dateRanges.find((_dateRange) => _dateRange.id === event.target.value);
		setDateRangeState(value);
	};

	if (dateRanges.length === Constants.TREND_DATE_RANGES.length) {
		let additionalRanges = [];
		let thisYear = new Date().getFullYear();

		for (let yearIndex = 1; yearIndex <= differenceInCalendarYears(trendData[trendData.length - 1].date, trendData[0].date); yearIndex++) {
			additionalRanges.push({
				id: (thisYear - yearIndex).toString(),
				label: (thisYear - yearIndex).toString(),
				format: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
				legendFormat: Constants.DATE_FORMATS.MONTH_NAME_FORMAT,
				title: 'By Week',
				tag: 'Week of ',
			});
		}

		if (trendData[0].date.getFullYear() === trendData[trendData.length - 1].date.getFullYear()) {
			dateRanges.splice(-1);
		}

		let newValue = [...dateRanges, ...additionalRanges];
		if (newValue.length !== dateRanges.length) {
			setDateRangesState(newValue);
		}
	}

	const lakeInfoProps = {
		location: location,
		user: user,
	};

	let mainContainerClassName = 'trends-container';
	if (isTablet) {
		mainContainerClassName = mainContainerClassName + ' tablet';
	}
	if (collapseTrends) {
		mainContainerClassName = mainContainerClassName + ' collapsed';
	}

	const waterComponentsProps = {
		period: period,
		dateRange: dateRange,
		satelliteView: satelliteView,
	};

	const getTrendsTabContent = () => {
		return (
			<>
				{!showAlerts ? (
					<Typography className="trends-title" style={{ color: '#000000', opacity: '0.75', letterSpacing: '-0.28px' }}>
						{trendsTitle}
					</Typography>
				) : (
					''
				)}

				<div style={{ padding: '9px 8px 13px 16px', height: '41px', display: 'flex' }}>
					<Select value={dateRange.id} className="select-period" onChange={setDateRange} displayEmpty inputProps={{ 'aria-label': 'Without label' }}>
						{dateRanges.map((_dateRange) => (
							<MenuItem key={'c' + Math.floor(Math.random() * 99999999999) + _dateRange.id} value={_dateRange.id}>
								<AccessTimeIcon style={{ height: '16px', width: '16px' }} fontSize="small" /> <span style={{ marginLeft: '6px' }}> {_dateRange.label}</span>
							</MenuItem>
						))}
					</Select>
				</div>

				{<BodySection {...bodyProps} />}
				{<BottomSection {...bottomProps} />}
				{isTablet ? '' : userMode === 'expert' && !isSaltWater ? <WaterComponents {...waterComponentsProps} /> : ''}
				<hr style={{ margin: '0' }} />

				<LakeInfo {...lakeInfoProps} />
			</>
		);
	};

	const readAlerts = async (unreadAlerts) => {
		const isUnreadAlert = unreadAlerts && unreadAlerts[0] && unreadAlerts[0].status === ALERTS_UNREAD_STATUS ? true : false;
		const selectedAlert = await readAllAlertsService(alertsNumber, alerts, setAlerts, unreadAlerts);
		if (selectedAlert && selectedAlert.value && selectedAlert.value.metadata && selectedAlert.value.timestamp) {
			const date = new Date(selectedAlert.value.timestamp);

			if (isUnreadAlert && selectedAlert.value.status === ALERTS_READ_STATUS) {
				let newAlertNumber = alertsNumber - 1;
				newAlertNumber = newAlertNumber > 0 ? newAlertNumber : 0;
				setAlertsNumber(newAlertNumber);
			}

			let modifiedDate = format(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
			const index = location.satellites[SATELLITES.copernicus].dates.findIndex((el) => el === modifiedDate);
			if (index < 0) {
				return '';
			}
			modifiedDate = dayjs(modifiedDate);
			await setSelectedDate(modifiedDate);
			return showLayersAfterDateChange(modifiedDate['$d']);
		} else {
			return '';
		}
	};

	const compareDates = (a, b) => {
		let aDate = new Date(a.value.timestamp);
		let bDate = new Date(b.value.timestamp);
		return bDate - aDate;
	};
	const getAlertsTabContent = () => {
		const zones = getZonesList(alerts);
		const timeFilters = getTimeFilters();
		let filteredAlerts = alerts;
		filteredAlerts.sort(compareDates);
		filteredAlerts = filterAlerts(filteredAlerts, alertsFilter);

		return (
			<>
				{' '}
				<AlertsFilters timeFilters={timeFilters} alertsFilter={alertsFilter} zones={zones} setAlertsFilter={setAlertsFilter} />
				<AlertsList readAlerts={readAlerts} alerts={filteredAlerts} />{' '}
			</>
		);
	};

	const renderTabContent = () => {
		if (selectedTab === TRENDS_WATER_COMPONENTS_TAB) {
			return getTrendsTabContent();
		} else if (selectedTab === TRENDS_ALERTS_TAB) {
			return getAlertsTabContent();
		}
	};

	const handleChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	const alertsLabelComponent = (
		<span style={{ display: 'flex' }}>
			<span style={{ marginRight: '5px' }} className={`tabs-text ${selectedTab === TRENDS_WATER_COMPONENTS_TAB ? '' : 'selected'}`}>
				{alertsTitle}{' '}
			</span>
			{alertsNumber ? <span className="alerts-number" style={{ background: alertsNumberColour }}>{`${alertsNumber}`}</span> : ''}
		</span>
	);

	const trendLabelTitle = (
		<span style={{ display: 'flex' }}>
			<span className={`tabs-text ${selectedTab === TRENDS_ALERTS_TAB ? '' : 'selected'}`}> {trendsTitle} </span>
		</span>
	);

	return (
		<div className={mainContainerClassName}>
			<MainSection {...mainSectionProps} tempratureMin={tempratureMin} tempratureMax={showAverageRange ? tempratureMax : weatherValues.temperature_2m} />
			{showAlerts ? (
				<div style={{ padding: '14px 0px', boxSizing: 'content-box' }}>
					<Tabs value={selectedTab} onChange={handleChange} className="tabs-container">
						<Tab value={TRENDS_WATER_COMPONENTS_TAB} label={trendLabelTitle} className="tab-button" />
						<Tab value={TRENDS_ALERTS_TAB} label={alertsLabelComponent} wrapped className="tab-button" />
					</Tabs>
				</div>
			) : (
				''
			)}
			{renderTabContent()}
		</div>
	);
}
